import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { EyeIcon, EyeOffIcon } from 'lucide-react';
import axiosInstance from '../../features/auth/api';


const ChangePassword = () => {
    const [showPassword, setShowPassword] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    const handleChangePassword = async (values, setSubmitting) => {
       try {
            const response = await axiosInstance.post(`/admin/change-password`, values);
           
            const { data: responseData } = response;

            if (!responseData.status) {
                setSubmitting(false);
                alert(responseData.message);
                return;
            }

            alert(responseData.message);
            setSubmitting(false);
            formik.resetForm();

        } catch (error) {
            setSubmitting(false);
            alert(error.message);
        }
    }


    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required('Old Password is required'),
            newPassword: Yup.string().required('New Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character'),
            confirmPassword: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('newPassword'), null], 'Passwords do not match'),
        }),
        onSubmit: (values, { setSubmitting }) => {
            handleChangePassword(values, setSubmitting);
        }
    });

    return (
        <div className='container mx-auto p-10 bg-white rounded-lg'>
            <h1 className='text-2xl font-bold'>Change Password</h1>

            <form className='mt-5' onSubmit={formik.handleSubmit}>
                <div className='flex flex-col mb-3'>
                    <label htmlFor="oldPassword">Old Password</label>
                    <div className='relative border border-gray-300 w-full rounded-md p-2'>
                        <input type={showPassword.oldPassword ? 'text' : 'password'} id="oldPassword" className="text-sm w-full ring-0 focus:ring-0 focus:outline-none" value={formik.values.oldPassword} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {!showPassword.oldPassword ? <EyeIcon className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => setShowPassword({ ...showPassword, oldPassword: !showPassword.oldPassword })} /> : <EyeOffIcon className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => setShowPassword({ ...showPassword, oldPassword: !showPassword.oldPassword })} />}
                    </div>
                    {formik.touched.oldPassword && formik.errors.oldPassword ? <span className='text-sm text-red-500'>{formik.errors.oldPassword}</span> : null}
                </div>

                <div className='flex flex-col mb-3'>
                    <label htmlFor="newPassword">New Password</label>
                    <div className='relative border border-gray-300 w-full rounded-md p-2'>
                        <input type={showPassword.newPassword ? 'text' : 'password'} id="newPassword" className="w-full ring-0 focus:ring-0 text-sm  focus:outline-none" value={formik.values.newPassword} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {!showPassword.newPassword ? <EyeIcon className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword })} /> : <EyeOffIcon className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword })} />}
                    </div>
                    {formik.touched.newPassword && formik.errors.newPassword ? <span className='text-sm text-red-500'>{formik.errors.newPassword}</span> : null}
                </div>
                <div className='flex flex-col mb-3'>
                    <label htmlFor="newPassword">Confirm Password</label>
                    <div className='relative border border-gray-300 w-full rounded-md p-2'>
                        <input type={showPassword.confirmPassword ? 'text' : 'password'} id="confirmPassword" className="w-full ring-0 text-sm  focus:ring-0 focus:outline-none" value={formik.values.confirmPassword} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {!showPassword.confirmPassword ? <EyeIcon className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })} /> : <EyeOffIcon className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })} />}
                    </div>
                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? <span className='text-sm text-red-500'>{formik.errors.confirmPassword}</span> : null}
                </div>

                <button disabled={formik.isSubmitting} type="submit" className='bg-primary text-white px-4 py-2 rounded-md mt-5'>{formik.isSubmitting ? 'Changing Password...' : 'Change Password'}</button>
            </form>
        </div>
    )
}

export default ChangePassword