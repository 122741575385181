
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../features/auth/api';
import { apiEndpoint } from '../../config/constant';
import moment from 'moment';
import DataTable from "react-data-table-component"
import { ChevronDown, ChevronLeft, ChevronRight, Eye } from 'lucide-react';
import ReactPaginate from 'react-paginate';
import SendReplyModal from './SendReplyModal';
const SupportPage = () => {

    const [userData, setUserData] = useState({
        data: [],
        total: 0
    });
    const [loading, setLoading] = useState(false);
    const [tableConfig, setTableConfig] = useState({
        page: 1,
        limit: 10,
        order: 'desc',
        sortBy: 'createdAt'
    })

    const [sendReplyModalInfo, setSendReplyModalInfo] = useState({
    });


    const fetchUserMessages = async () => {
        try {
            const response = await axiosInstance.post('/message/list', tableConfig);
            if (!response.data.status) return

            setUserData({
                data: response.data.data.data,
                total: response.data.data.total
            });
        } catch (err) {
            // console.error(err);
        }
    };

    const handlePageClick = (page) => {
        setTableConfig({ ...tableConfig, page: page.selected + 1 });
    }


    const handleSort = (column, sortDirection) => {
        setTableConfig({ ...tableConfig, order: sortDirection, sortBy: column['sortField'] });
    }

    const handleReplySubmit = async () => {
        setSendReplyModalInfo({})
        await fetchUserMessages();
    }


    const columns = [
        {
            name: 'User Name/Address',
            minWidth: '138px',
            sortable: false,
            sortField: 'userAddress',
            selector: (row) => row.userAddress,
            cell: (row) => <span className='text-capitalize'>{row?.userName || row?.userAddress}</span>
        },
        {
            name: 'User Email',
            minWidth: '138px',
            sortable: false,
            sortField: 'userEmail',
            selector: (row) => row.email,
            cell: (row) => <span>{row.email}</span>
        },

        {
            name: 'Message',
            minWidth: '138px',
            sortable: false,
            sortField: 'message',
            selector: (row) => row.message,
            cell: (row) => <span>{row.message ? <span className='flex flex-row items-center gap-2'>
                {row.message.toString().substring(0, 10)}... <Eye onClick={() => setSendReplyModalInfo(row)} className='w-4 h-4 cursor-pointer' />
            </span> : 'N/A'}</span>
        },
        {
            name: 'Status',
            minWidth: '138px',
            sortable: false,
            sortField: 'status',
            selector: (row) => row.status,
            cell: (row) => <span className='uppercase'>{
                row.status == 'pending' ? <span className='bg-yellow-500 text-white px-2 py-1 rounded-md'>Pending</span> : <span className='bg-green-500 text-white px-2 py-1 rounded-md'>Resolved</span>
            }</span>
        },

        {
            name: 'Date',
            minWidth: '138px',
            sortable: true,
            sortField: 'createdAt',
            selector: (row) => row.createdAt,
            cell: (row) => <span>{moment(row.createdAt).format('MMM-DD-YYYY')}</span>
        },
        {
            name: 'Action',
            minWidth: '138px',
            sortable: false,
            sortField: 'action',
            selector: (row) => row.action,
            cell: (row) => <div className='flex justify-center items-center gap-2'>
                <button role='button' onClick={() => setSendReplyModalInfo(row)} className='bg-primary text-white px-2 py-1 rounded-md'>
                    Send Reply
                </button>

            </div>
        }
    ]

    const CustomPagination = () => {
        const count = Number(Math.ceil(userData.total / tableConfig.limit))
        return (
            <div className="mt-5 flex flex-row items-center justify-between">
                <ReactPaginate
                    previousLabel={<ChevronLeft className="w-5 h-5 text-white" />}
                    nextLabel={<ChevronRight className="w-5 h-5 text-white" />}
                    pageCount={count || 1}
                    forcePage={tableConfig.page !== 0 ? tableConfig.page - 1 : 0}
                    onPageChange={page => handlePageClick(page)}
                    activeClassName="bg-main text-white rounded-full bg-blue-500"
                    pageClassName="page-item"
                    pageLinkClassName="page-link flex items-center justify-center h-8 w-8 text-sm bg-main/60 text-white rounded-full hover:bg-main hover:text-white"
                    nextClassName="page-item next"
                    nextLinkClassName="page-link flex items-center justify-center h-8 w-8 text-sm bg-main text-white rounded-full hover:bg-main/90 bg-green-500"
                    previousClassName="page-item prev"
                    previousLinkClassName="page-link flex items-center justify-center h-8 w-8 text-sm bg-main text-white rounded-full hover:bg-main/90 bg-green-500"
                    containerClassName="pagination flex flex-row items-center justify-end space-x-2"
                />
            </div>
        );
    }


    useEffect(() => {
        fetchUserMessages();
    }, [tableConfig]);

    return (
        <>
            {loading && <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="w-10 h-10 border-t-2 border-b-2 border-primary rounded-full animate-spin"></div>
            </div>}


            <div className="min-h-screen bg-background text-white p-4">
                <h1 className="text-4xl font-bold text-primary mb-4">
                    Messages
                </h1>
                <div className="bg-gray-800 p-4 rounded">

                    <DataTable
                        noHeader
                        sortServer
                        pagination
                        responsive
                        paginationServer
                        columns={columns}
                        onSort={handleSort}
                        tableContainerClass='react-dataTable bg-gray-800 text-white'
                        sortIcon={<ChevronDown />}
                        className='react-dataTable bg-gray-800 text-white'
                        paginationComponent={CustomPagination}
                        data={userData.data || []}
                        totalRows={userData.total}
                    />
                </div>
                {userData?.data?.length == 0 && <div className="text-white text-center font-bold text-2xl items-center h-full justify-center"><p>No data found</p></div>}
            </div >

            {Object.keys(sendReplyModalInfo).length > 0 && <SendReplyModal
                isOpen={Object.keys(sendReplyModalInfo).length > 0}
                handleClose={() => setSendReplyModalInfo({})}
                handleSubmit={handleReplySubmit}
                data={sendReplyModalInfo}
            />}
        </>

    );
};

export default SupportPage;
