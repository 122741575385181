import React, { useEffect, useState } from 'react';
import axiosInstance from '../../features/auth/api';
import moment from 'moment';
import DataTable from "react-data-table-component"
import { ChevronDown, ChevronLeft, ChevronRight, Eye, MoreVerticalIcon } from 'lucide-react';
import ReactPaginate from 'react-paginate';
const WithdrawalRequestPage = () => {

  const [withdrwalHistory, setWithdrawalHistory] = useState({
    data: [],
    total: 0
  });
  const [loading, setLoading] = useState(false);
  const [tableConfig, setTableConfig] = useState({
    page: 1,
    limit: 10,
    order: 'desc',
    sortBy: 'createdAt'
  })


  const fetchWithdrawalHistory = async () => {
    try {
      const response = await axiosInstance.post('/admin/deposit-history', tableConfig);
      if (!response.data.status) return 
      
      setWithdrawalHistory({
        data: response.data.data.data,
        total: response.data.data.total
      });
    } catch (err) {
      // console.error(err);
    }
  };

  const handlePageClick = (page) => {
    setTableConfig({ ...tableConfig, page: page.selected + 1 });
  }


  const handleSort = (column, sortDirection) => {
    setTableConfig({ ...tableConfig, order: sortDirection, sortBy: column['sortField']});
  }


  const columns = [
    {
      name: 'UserId',
      sortable: true,
      maxWidth: '50px',
      sortField: 'userId',
      className: 'bg-gray-800',
      selector: (row) => row.userId,
      cell: (row) => (
        <div className='d-flex justify-content-left align-items-center'>
          {row.userId}
        </div>
      )
    },
    {
      name: 'User Name/Address',
      minWidth: '138px',
      sortable: false,
      sortField: 'userAddress',
      selector: (row) => row.userAddress,
      cell: (row) => <span className='text-capitalize'>{row?.user?.userName || row?.user?.userAddress}</span>
    },
    {
      name: 'Amount',
      minWidth: '138px',
      sortable: true,
      sortField: 'amount',
      selector: (row) => row.amount,
      cell: (row) => <span>{row.amount} SOL</span>
    },
    {
      name: 'Status',
      minWidth: '138px',
      sortable: true,
      sortField: 'status',
      selector: (row) => row.status,
      cell: (row) => <span className='uppercase'>{row.status}</span>
    },
    {
      name: 'Date',
      minWidth: '138px',
      sortable: true,
      sortField: 'createdAt',
      selector: (row) => row.createdAt,
      cell: (row) => <span>{moment(row.createdAt).format('MMM-DD-YYYY')}</span>
    },
  ]

  const CustomPagination = () => {
    const count = Number(Math.ceil(withdrwalHistory.total / tableConfig.limit))
    return (
      <div className="mt-5 flex flex-row items-center justify-between">
        <ReactPaginate
          previousLabel={<ChevronLeft className="w-5 h-5 text-white" />}
          nextLabel={<ChevronRight className="w-5 h-5 text-white" />}
          pageCount={count || 1}
          forcePage={tableConfig.page !== 0 ? tableConfig.page - 1 : 0}
          onPageChange={page => handlePageClick(page)}
          activeClassName="bg-main text-white rounded-full bg-blue-500"
          pageClassName="page-item"
          pageLinkClassName="page-link flex items-center justify-center h-8 w-8 text-sm bg-main/60 text-white rounded-full hover:bg-main hover:text-white"
          nextClassName="page-item next"
          nextLinkClassName="page-link flex items-center justify-center h-8 w-8 text-sm bg-main text-white rounded-full hover:bg-main/90 bg-green-500"
          previousClassName="page-item prev"
          previousLinkClassName="page-link flex items-center justify-center h-8 w-8 text-sm bg-main text-white rounded-full hover:bg-main/90 bg-green-500"
          containerClassName="pagination flex flex-row items-center justify-end space-x-2"
        />
      </div>
    );
  }


  useEffect(() => {
    fetchWithdrawalHistory();
  }, [tableConfig]);


  const getBtnClassName = (approveStatus) => {

    let btnClassName = 'text-white bg-gradient-to-r h-8 from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 text-center me-2 mb-2'
    if (approveStatus == 'approved_step1') {
      btnClassName = 'text-white bg-gradient-to-r h-8 from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 text-center me-2 mb-2'
    }
    return btnClassName;

  }

  return (
    <>
      {loading && <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="w-10 h-10 border-t-2 border-b-2 border-primary rounded-full animate-spin"></div>
      </div>}


      <div className="min-h-screen bg-background text-white p-4">
        <h1 className="text-4xl font-bold text-primary mb-4">Deposits</h1>
        <div className="bg-gray-800 p-4 rounded">
          {/* <table className="w-full text-left">
            <thead>
              <tr>
                <th className="p-2">Order Id</th>
                <th className="p-2">Amount</th>
                <th className="p-2">Status</th>
                <th className="p-2">Date</th>
                <th className="p-2">Approve</th>
              </tr>
            </thead>
            <tbody>
              {withdrwalHistory?.map((withdrawal, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 text-white">{withdrawal.orderId}</td>
                  <td className="px-4 py-2 text-white">{withdrawal.amount} SOL</td>
                  <td className="px-4 py-2 text-white">{withdrawal.status}</td>
                  <td className="px-4 py-2 text-white">{moment(withdrawal.createdAt).format('DD-MM-YYYY')}</td>
                  {((withdrawal.status == 'pending')) && <button onClick={() => approvedByadmin({ assets: withdrawal.assetId, id: withdrawal.userId, withdrwalAdress: withdrawal.withdrwalAdress, approveStatus: withdrawal.approveStatus, orderId: withdrawal['orderId'], amount: withdrawal['amount'], status: withdrawal['status'] })} type="button" className={`${getBtnClassName(withdrawal.approveStatus)}`}>
                    {withdrawal.approveStatus == 'pending' ? 'Approve' : withdrawal.approveStatus == 'approved_step1' ? 'Confirm' : ''}
                  </button>}
                </tr>

              ))}
            </tbody>
          </table> */}
          <DataTable
            noHeader
            sortServer
            pagination
            responsive
            paginationServer
            columns={columns}
            onSort={handleSort}
            tableContainerClass='react-dataTable bg-gray-800 text-white'
            sortIcon={<ChevronDown />}
            className='react-dataTable bg-gray-800 text-white'
            paginationComponent={CustomPagination}
            data={withdrwalHistory.data || []}
            totalRows={withdrwalHistory.total}
          />
        </div>
        {withdrwalHistory?.data?.length == 0 && <div className="text-white text-center font-bold text-2xl items-center h-full justify-center"><p>No data found</p></div>}
      </div >
    </>

  );
};

export default WithdrawalRequestPage;
