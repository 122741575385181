// src/pages/Dashboard.js
import React,{useState,useEffect} from 'react';
import axiosInstance from '../../features/auth/api';
import { apiEndpoint } from '../../config/constant';



const Dashboard = () => {


  const [dashboardData, setDashboardData] = useState({
    totalUsers: 0,
    totalRevenue: 0,
    totalGames: 0
  });

  
useEffect(() => {
  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get(apiEndpoint.GETDASHBOARD);
      const { totalUsers, totalRevenue, totalGames } = response.data.data;
      setDashboardData({
        totalUsers: totalUsers,
        totalRevenue: totalRevenue,
        totalGames: totalGames
      });
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  fetchUsers();
}, []);
  return (

      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-y-auto p-6 bg-gray-100">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">Users</h2>
              <p>Total Users: {dashboardData.totalUsers}</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">Revenue</h2>
              <p>Total Revenue: {dashboardData.totalRevenue} SOL</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">Total Games</h2>
              <p>Total Games: {dashboardData.totalGames}</p>
            </div>
          </div>
        </main>
      </div>

  );
};

export default Dashboard;
