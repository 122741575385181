export const apiEndpoint = {
    FetchGameData: 'admin/get-game-list',
    UPLOADIMAGES: 'admin/images-uploads',
    SETGAMES: 'admin/set-games/',
    GETUSER: 'admin/fetch-users',
    GETDASHBOARD: 'admin/dashboard',
    FETCHUSERMESSAGES: 'admin/fetch-user-messages',
    FETCHINGHOMEPAGEINFO: '/admin/customisation/home-info',
    UPDATESECTIONONE: 'admin/customisation/edit-section-one',
    UPDATELOGO : 'admin/customisation/edit-logo', 
    FETCHSOCIALLINKS: 'admin/customisation/get-social-links',
    UPDATESECTIONTWO: 'admin/customisation/edit-section-two',
    UPDATESECTIONTHREE : 'admin/customisation/edit-section-three',
    UPDATESECTIONFOUR : 'admin/customisation/edit-section-four',
    UPDATESOCIALMEDIA:  'admin/customisation/edit-social-media',
}