import React from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import axiosInstance from '../../features/auth/api';

const SendReplyModal = ({ isOpen, handleClose, handleSubmit, data }) => {


    const handleReplySubmit = async (values, resetForm, setSubmitting) => {
        try {
            setSubmitting(true);
            
            const response = await axiosInstance.post('/message/send-reply', {
                id: data.id,
                reply: values.reply,
                status: values.status
            })


            const { data: responseData } = response

            if (!responseData.status) {
                setSubmitting(false);
                toast.error(responseData.message, {
                    position: 'bottom-center',
                    autoClose: 3000,
                });
                return
            }

            toast.success('Reply sent successfully', {
                position: 'bottom-center',
                autoClose: 3000,
            });
            setSubmitting(false);
            resetForm();
            handleSubmit();

        } catch (error) {
            setSubmitting(false);
            toast.error('Something went wrong', {
                position: 'bottom-center',
                autoClose: 3000,
            });
        }
    }

    const statusOptions = [
        { value: 'pending', label: 'Pending' },
        { value: 'resolved', label: 'Resolved' },
    ]

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            reply: '',
            status: data['status']
        },
        validationSchema: yup.object({
            reply: yup.string(),
            status: yup.string()
        }),
        onSubmit: (values, { resetForm, setSubmitting}) => {
            handleReplySubmit(values, resetForm, setSubmitting);
        }
    })

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-100 ">

            <div className="text-black rounded-lg p-6 w-full max-w-md relative  bg-white max-h-[90vh]  overflow-y-scroll">
                
                <div className=''>
                    <div className='flex justify-between items-center'>

                    Email: {data.email}
                    <button
                    type="button"
                    onClick={handleClose}
                    role='button'
                    className="bg-gray-900 text-xs text-white px-3 py-1 rounded-md cursor-pointer"
                >
                    Close
                </button> 
                    </div>
                    <br />
                    <br />

                    Message: {data.message}
                    <form className='mt-5' onSubmit={formik.handleSubmit}>
                        <div className='flex flex-col gap-2'>
                            <label htmlFor='reply' className='text-xs font-bold'>Reply (Optional)</label>
                            <textarea id='reply' name='reply' className='w-full h-32 p-2 border border-gray-300 rounded-lg' value={formik.values.reply} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            {formik.touched.reply && formik.errors.reply && <p className='text-red-500'>{formik.errors.reply}</p>}
                        </div>

                        <div className='flex flex-col gap-2'>
                            <label htmlFor='status' className='text-xs font-bold'>Status</label>
                            <select id='status' name='status' className='w-full p-2 border border-gray-300 rounded-lg' value={formik.values.status} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                {statusOptions.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>

                        <div className='flex gap-2 justify-end mt-5'>
                            <button disabled={formik.isSubmitting} type='button' onClick={handleClose} className='bg-gray-500 text-white px-5 py-2 rounded-md cursor-pointer'>Cancel</button>
                            <button disabled={formik.isSubmitting} type='submit' className='bg-gray-900 text-white px-5 py-2 rounded-md cursor-pointer'>
                                {formik.isSubmitting ? 'Sending...' : 'Send'}
                            </button>
                            
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default SendReplyModal