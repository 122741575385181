import React, { useContext, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { login } from '../../features/auth/authSlice';
import { DataContext } from '../../Context/PageContext';

const AdminLoginPage = () => {
const {loginApi} = useContext(DataContext)
  const [user_name, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // const dispatch = useDispatch();
  // const authStatus = useSelector((state) => state.auth.status);
  // const authError = useSelector((state) => state.auth.error);
  // console.log(authError?.message,authStatus);

  const handleLogin = (e) => {
    e.preventDefault();
    loginApi({ user_name, password });
  };

  return (
    <div className="min-h-screen bg-background flex items-center justify-center text-white">
      <div className="bg-gray-800 p-8 rounded shadow-md w-full max-w-sm">
        <h1 className="text-2xl font-bold text-primary mb-4">Admin Login</h1>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block mb-2">Username</label>
            <input
              type="text"
              value={user_name}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full p-2 bg-gray-700 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 bg-gray-700 rounded"
              required
            />
          </div>
          <button type="submit" className="w-full bg-primary hover:bg-red-700 text-white font-bold py-2 rounded">
            Login
          </button>
          {/* {authError && <p>{authError.message}</p>} */}
        </form>
      </div>
    </div>
  );
};

export default AdminLoginPage;
